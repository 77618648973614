import { withZod } from '@remix-validated-form/with-zod'
import { z } from 'zod'
import { zfd } from 'zod-form-data'

export const validationObj = z.object({
    couponCode: z.string().min(2),
  })

export const dataValidator = zfd.formData(validationObj)

export const validator = withZod(validationObj)
