import { TrashIcon } from '@heroicons/react/24/outline';
import { SyntheticEvent, useCallback } from 'react';
import { ValidatedForm, useIsSubmitting } from 'remix-validated-form'
import { Button } from '~ui/Button';
import { LoadingIndicator } from '~ui/LoadingIndicator';
import { useLocation } from '@remix-run/react'
import { validator } from './validator';

const RemoveCouponForm: React.FC<{
  couponCode: string
  currentCouponCodes: string[]
  className?: string
  // loading?: boolean
  onRemoveCoupon?: (couponCode: string, couponCodes: string[]) => void
}> = ({ couponCode, currentCouponCodes = [], className, onRemoveCoupon }) => {
  const isSubmitting = useIsSubmitting(`remove-coupon-form-${couponCode}`)
  const location = useLocation()
  // const handleRemoveCoupon = useCallback(
  //   (e: SyntheticEvent<HTMLButtonElement>) => {
  //     if (onRemoveCoupon) {
  //       onRemoveCoupon(couponCode, currentCouponCodes)
  //     }
  //   },
  //   [onRemoveCoupon],
  // )

  return (
    <ValidatedForm
      id={`remove-coupon-form-${couponCode}`}
      method="POST"
      validator={validator}
      action={location.pathname}
      resetAfterSubmit
    >
      <div
        className={`flex items-center text-base font-medium text-gray-700 ${className}`}
      >
        <input type="hidden" name="action" value="removeCoupon" />
        <input type="hidden" name="intent" value="removeCouponCode" />
        <input type="hidden" name="couponCode" value={couponCode} />
        <input
          type="hidden"
          name="currentCouponCodes"
          value={currentCouponCodes.join(',')}
        />
        <p className="mr-1 font-bold dark:text-slate-300">{couponCode}</p>
        <Button
          type="submit"
          disabled={isSubmitting}
          variant="secondary"
          className="focus:ring-transparent ml-1"
          // onClick={handleRemoveCoupon}
        >
          {isSubmitting ? (
            <LoadingIndicator className="w-4 h-4" />
          ) : (
            <TrashIcon className="h-4 w-4 text-gray-600 hover:text-gray-900 dark:text-slate-400" />
          )}
        </Button>
      </div>
    </ValidatedForm>
  )
}

export { RemoveCouponForm };
